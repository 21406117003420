body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button#rcc-decline-button,
button#rcc-confirm-button {
  margin: unset !important;
  margin-right: 15px !important;
}

button#rcc-decline-button {
  margin-left: 15px !important;
}

button#rcc-confirm-button {
  background: rgba(76, 161, 86) !important;
  color: rgb(229, 229, 229) !important;
}