.my_content {
  min-height: calc(100vh - 117.5px);
}

body {
  background-color: #ffffff;
}

.App {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
}

#logo {
  transition: all ease 500ms;
}

#logo:hover {
  transform: scale(1.1);
  opacity: 0.7;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

footer a {
  color: inherit;
  outline: none;
}

.card,
.card-img,
.card-img-top {
  border-top-left-radius: calc(0rem);
  border-top-right-radius: calc(0rem);
  border-radius: 0.25rem;
}

.card-img-top {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.rounded {
  border-radius: 0rem !important;
}

.card .bg-image {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.card-body {
  padding: 1rem 1rem;
}

.btn {
  border-radius: 0.15rem;
}